import { FC, useEffect, useState } from 'react';
import {
  Paragraph,
  Col,
  Row,
  Title,
  Loader,
  StaleInfo,
  StaleInputSelect,
} from 'components';
import { useTheme } from 'styled-components';
import Button from 'components/shared/Button/Button';
import { Controller, useForm } from 'react-hook-form7';
import { StyledForm } from 'components/shared/Form/Form.styles';
import * as FirebasePaymentInitiation from 'services/firebase/paymentInitiation';
import { errorHandler } from 'utils/errors';
import {
  IOpenBankingInstitution,
  IOpenBankingAccount,
  ICurrentBalance,
} from 'types';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { Notify } from 'utils';
import { ERROR_MESSAGES } from 'variables';
import { subscribeToCurrentBalances } from 'services/balances';
import { useStoreState } from 'state';

interface IOwnProps {
  onContinue: () => void;
  transactionID?: string;
  selectedInstitution?: IOpenBankingInstitution;
}

interface IInputs {
  [openBankingAccountId: string]: string;
}

const StepThree: FC<IOwnProps> = ({
  onContinue,
  transactionID,
  selectedInstitution,
}) => {
  const theme = useTheme();
  const { entityId } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);
  const [openBankingAccounts, setOpenBankingAccounts] = useState<
    IOpenBankingAccount[]
  >([]);
  const [currentBalances, setCurrentBalances] = useState<ICurrentBalance[]>([]);

  useEffect(() => {
    if (!transactionID) {
      return;
    }

    callExternalApiWithLoading({
      externalApiCall: () =>
        FirebasePaymentInitiation.getOpenBankingAccounts(transactionID),
      loadingHandler: setIsLoading,
      responseHandler: (response) => {
        if (response.data.data) {
          setOpenBankingAccounts(response.data.data);
        }
      },
    });
  }, [transactionID]);

  useEffect(() => {
    let unsubscribeFromCurrentBalances: (() => void) | undefined;

    if (entityId) {
      unsubscribeFromCurrentBalances = subscribeToCurrentBalances({
        entityId,
        callback: (balances) => setCurrentBalances(balances),
      });
    }

    return () => {
      unsubscribeFromCurrentBalances?.();
    };
  }, [entityId]);

  const { control, handleSubmit } = useForm<IInputs>({
    mode: 'all',
  });

  const onSubmit = async (values: IInputs) => {
    try {
      const openBankingAccountIds = Object.keys(values);

      const payload = openBankingAccountIds.map((openBankingAccountId) => ({
        externalAccountingSystemId: values[openBankingAccountId],
        openBankingAccountId,
      }));

      const {
        data,
      } = await FirebasePaymentInitiation.postOpenBankingAccountMappingInstructions(
        payload
      );
      if (data.success) {
        Notify.success('Accounts mapped successfully');
        onContinue();
      } else {
        errorHandler(data?.message);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  if (isLoading) {
    return <Loader size="large" />;
  }

  return (
    <StyledForm id="map-accounts-form" onSubmit={handleSubmit(onSubmit)}>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Map accounts
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        Your account data from {selectedInstitution?.name} is now connected and
        will be automatically updated each time you login or we need to run
        reports for you.
      </Paragraph>

      <Row mb alignSelf="stretch">
        <Paragraph variant="bold"> Map your accounts</Paragraph>
        <StaleInfo mode="hover" strategy="fixed" placement="top">
          <Paragraph color="white">
            Select correct account(s) from your ERP or accounting system to map
            Open Banking data feed to HedgeFlows for your balances and
            transactions.
          </Paragraph>
        </StaleInfo>
      </Row>

      <Col alignSelf="stretch" gap={theme.spacing.m}>
        {openBankingAccounts.map((account) => {
          return (
            <Col key={account.id} gap={theme.spacing.xs}>
              <Paragraph variant="bold">
                {account.description} - {account.accountNumber}
              </Paragraph>

              <Controller
                name={account.id}
                control={control}
                rules={{ required: ERROR_MESSAGES.requiredField }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <StaleInputSelect
                        id={name}
                        name={name}
                        label="Account to map"
                        view="moving"
                        data={currentBalances.map((mappingAccount) => ({
                          id: mappingAccount.sourceSystemId,
                          name: mappingAccount.name,
                          value: mappingAccount.sourceSystemId,
                        }))}
                        selected={value}
                        onSelect={(item) => onChange(item.id)}
                        strategy="fixed"
                      />

                      {error && (
                        <Paragraph color="red">{error.message}</Paragraph>
                      )}
                    </>
                  );
                }}
              />
            </Col>
          );
        })}
      </Col>

      <Row alignSelf="stretch">
        <Button
          mt
          mtValue={theme.spacing.xl}
          flex={1}
          disabled={isLoading}
          form="map-accounts-form"
        >
          Save
        </Button>
      </Row>
    </StyledForm>
  );
};

export default StepThree;
