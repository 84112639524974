import styled, { css } from 'styled-components';
import { Row } from '../Row/Row';
import { Col } from '../Col/Col';
import { StaleField } from '../StaleField/StaleField';

export const AddContactFormWrapper = styled.div`
  position: relative;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.white};
`;

export const FieldGroupWrapper = styled(Col)`
  flex: 1;
  align-self: stretch;
`;

export const TypesRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
  margin-left: ${({ theme }) => theme.spacing.xl};
  gap: ${({ theme }) => theme.spacing.xl};
`;

export const FieldWrapper = styled(StaleField)<{
  isCurrency?: boolean;
  accountGroup?: boolean;
}>(
  ({ isCurrency = false, accountGroup = false }) => css`
    ${accountGroup &&
    css`
      width: 100%;
      max-width: 100%;

      ${isCurrency &&
      css`
        max-width: 138px;
        margin-left: ${({ theme }) => theme.spacing.m};
      `}
    `}
  `
);

export const FieldRow = styled.div<{
  fullWidth?: boolean;
}>(
  ({ fullWidth = false }) => css`
    display: flex;
    width: ${fullWidth ? '100% ' : 'calc(50% - 8px)'};
  `
);
