import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _orderBy from 'lodash.orderby';
import _get from 'lodash.get';
import _omit from 'lodash.omit';
import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'state';
import { Notify } from 'utils';
import { DB_DATE_FORMAT } from 'variables';
import { Firebase } from 'services';

import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';
import {
  RECIPIENT_STATUS,
  IContact,
  ICountry,
  ICurrency,
  IRecipient,
  PAYMENT_TYPE,
  RECIPIENT_TYPE,
  TRANSFER_TYPE,
  IPurpose,
  Nullable,
  IRecipientMeta,
  TScamQuestions,
  PartialContact,
  isContact,
  IRecipientMetaField,
  IEntityDetails,
} from 'types';
import {
  LoaderWrapper,
  TypesRow,
  FieldWrapper,
  FieldRow,
  FieldGroupWrapper,
  AddContactFormWrapper,
} from './AddContactForm.styles';
import {
  StaleCheckboxControlled,
  Loader,
  StaleInputRadioNew,
  Row,
  StaleLimitedAccess,
  PermissionsChecker,
  Popup,
} from 'components';
import { Col } from '../Col/Col';
import Button from '../Button/Button';
import { Paragraph, Subtitle, Title } from '../Typography/Typography';
import { createCreatableSelectMenuOption } from '../CreatableSelectMenu/utils';
import { CreatableSelectMenuOption } from '../CreatableSelectMenu/types';
import StaleTooltip from '../StaleTooltip/StaleTooltip';
import { useTheme } from 'styled-components';
import { StyledForm } from '../Form/Form.styles';
import ScamAlert from './components/ScamAlert/ScamAlert';
import { isContactCountrySuspiciousCheck } from './utils';
import { errorHandler } from 'utils/errors';
import { createPortal } from 'react-dom';
import DirtyPopup from './components/DirtyPopup/DirtyPopup';
import AddContactFormField from './components/AddContactFormField/AddContactFormField';

export type AddContactInputs = {
  recipientName: string;
  recipientEmail: string;
  recipientCity: string;
  recipientPostcode: string;
  recipientAddress: string;
  bankName: string;
  accountNumber: string;
  bicSwift: string;
  recipientCountry: ICountry;
  recipientStateOrProvince?: string;
  routingNumber?: string;
  routingNumber2?: string;
  recipientEntityType?: RECIPIENT_TYPE;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactEmail: string;
  primaryContactPhoneNumber: string;
  isSupplier: boolean;
  isBuyer: boolean;
  accountDetails: string;
  purpose: {
    name: IPurpose['description'];
    id: IPurpose['description'];
    value: IContact['purpose'];
  };
  currency: {
    name: ICurrency['name'];
    id: ICurrency['id'];
    icon: ICurrency['countryCode'];
    value: ICurrency;
  };
  transferType: TRANSFER_TYPE;
  isTrusted: boolean;
  emails: CreatableSelectMenuOption[];
  shouldSendRemittance: boolean;
};

interface IOwnProps {
  metadata?: IRecipientMeta;
  initialCurrency: ICurrency;
  sellCurrency: ICurrency;
  initialTransferType: TRANSFER_TYPE;
  validateOnMount?: boolean;
  recipientForEdit?: Nullable<IContact | PartialContact>;
  onClose: () => void;
  onContinue?: () => void;
  setRecipient?: (state: IRecipient) => void;
  withDraftWarning?: boolean;
  withSaveAsDraft?: boolean;
  isForceCreate?: boolean;
  disableCurrency?: boolean;
}

const AddContactForm: FC<IOwnProps> = ({
  recipientForEdit,
  onClose,
  metadata: initialMetadata,
  initialCurrency,
  sellCurrency,
  initialTransferType,
  validateOnMount = false,
  setRecipient,
  onContinue,
  withDraftWarning = false,
  withSaveAsDraft = true,
  isForceCreate = false,
  disableCurrency = false,
}) => {
  const theme = useTheme();
  const {
    countries,
    countryByCode,
    getSwiftShared,
    getSwiftOurs,
  } = useStoreState(({ ReferenceDataState }) => ReferenceDataState);
  const { entityId, isEntityOnboarded } = useStoreState(
    ({ UserState }) => UserState
  );

  const { createRecipient, updateRecipient } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { getRecipientCreationMetadata } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { getTransferPurposes } = useStoreActions(
    ({ TransfersState }) => TransfersState
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm<AddContactInputs>({
    mode: 'all',
    defaultValues: {
      ...(recipientForEdit || {}),
      isTrusted: recipientForEdit?.isTrusted,
      primaryContactFirstName: recipientForEdit?.contactFirstName,
      primaryContactLastName: recipientForEdit?.contactLastName,
      primaryContactEmail: recipientForEdit?.contactEmail,
      isBuyer: recipientForEdit?.isCustomer,
      recipientCountry: recipientForEdit?.recipientCountry
        ? countryByCode(recipientForEdit.recipientCountry) || undefined
        : undefined,
      transferType: initialTransferType,
      currency: {
        name: initialCurrency.name,
        id: initialCurrency.id,
        icon: initialCurrency.countryCode,
        value: initialCurrency,
      },
      purpose: recipientForEdit?.purpose
        ? {
            name: recipientForEdit?.purpose?.description,
            id: recipientForEdit?.purpose?.description,
            value: recipientForEdit?.purpose,
          }
        : undefined,
      emails: recipientForEdit?.contactEmail
        ? recipientForEdit.contactEmail
            .split(',')
            .map(createCreatableSelectMenuOption)
        : [],
    },
  });

  const [isSwiftInputFocused, setIsSwiftInputFocused] = useState(false);
  const [routingType, setRoutingType] = useState(
    recipientForEdit?.routingType || null
  );
  const [routingType2, setRoutingType2] = useState(
    recipientForEdit?.routingType2 || null
  );
  const [isCreatingRecipient, setIsCreatingRecipient] = useState(false);
  const [isCreatingDraftRecipient, setIsCreatingDraftRecipient] = useState(
    false
  );
  const [countriesSearchValue, setCountriesSearchValue] = useState('');
  const [currenciesSearchValue, setCurrenciesSearchValue] = useState('');
  const [ibanBankData, setIbanBankData] = useState<any>(null);
  const [swiftBankData, setSwiftBankData] = useState<any>(null);
  const [routingNumberBankData, setRoutingNumberBankData] = useState<any>(null);
  const [accountCountry, setAccountCountry] = useState(
    recipientForEdit?.accountCountry
  );
  const [fieldsMetadata, setFieldsMetadata] = useState(initialMetadata);
  const [fieldGroups, setFieldGroups] = useState<IRecipientMetaField[]>([]);

  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);
  const [isPurposesLoading, setIsPurposesLoading] = useState(true);
  const [purposes, setPurposes] = useState<any>(null);

  const recipientEntityType = watch('recipientEntityType');
  const currencyValue = watch('currency');
  const transferType = watch('transferType');
  const recipientCountry = watch('recipientCountry');
  const emails = watch('emails');
  const purposeValue = watch('purpose');
  const bicSwiftValue = watch('bicSwift');

  const isAwaitingRegistration = useIsAwaitingRegistration();

  const [openDirtyPopup, setOpenDirtyPopup] = useState(false);
  const [openRegisterCompanyPopup, setOpenRegisterCompanyPopup] = useState(
    false
  );
  const [showScamAlert, setShowScamAlert] = useState(false);

  const [isLoadingCompanyDetails, setIsLoadingCompanyDetails] = useState(true);
  const [scamAlertAnswer, setScamAlertAnswer] = useState<
    TScamQuestions | undefined
  >(recipientForEdit?.scamAlert?.answer);

  const accountGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Account Details'),
    [fieldGroups]
  );

  const paymentGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Account details'),
    [fieldGroups]
  );

  const swiftGroupFields = useMemo(
    () =>
      fieldGroups?.filter(
        (item) =>
          item.title === 'Detailed address (required for SWIFT payments)'
      ),
    [fieldGroups]
  );

  const contactGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Contact details'),
    [fieldGroups]
  );

  const otherGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Additional information'),
    [fieldGroups]
  );

  const swiftOurs = accountCountry
    ? getSwiftOurs(sellCurrency.code, accountCountry)
    : 0;
  const swiftShared = getSwiftShared(sellCurrency.code);

  // set recipient entity type
  useEffect(() => {
    if (!recipientEntityType && fieldsMetadata) {
      const defaultRecipientType = fieldsMetadata?.company
        ? RECIPIENT_TYPE.company
        : RECIPIENT_TYPE.individual;

      setValue('recipientEntityType', defaultRecipientType);
    } else if (
      !recipientEntityType &&
      !fieldsMetadata?.company &&
      recipientEntityType === RECIPIENT_TYPE.company
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.individual);
    } else if (
      !recipientEntityType &&
      !fieldsMetadata?.individual &&
      recipientEntityType === RECIPIENT_TYPE.individual
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    } else if (
      recipientEntityType === RECIPIENT_TYPE.individual &&
      fieldsMetadata &&
      !fieldsMetadata?.individual
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    } else if (
      recipientEntityType === RECIPIENT_TYPE.company &&
      fieldsMetadata &&
      !fieldsMetadata?.company
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    }
  }, [fieldsMetadata, recipientEntityType, setValue]);

  // update account country based on bank details
  useEffect(() => {
    const bankDetails = swiftBankData || ibanBankData || routingNumberBankData;
    const bankDetailsCountry = _get(
      bankDetails,
      'bankCountry_ISO',
      ''
    ).toLowerCase();

    if (bankDetails && bankDetailsCountry !== accountCountry) {
      setAccountCountry(bankDetailsCountry);
    }
  }, [swiftBankData, ibanBankData, routingNumberBankData, accountCountry]);

  // set SWIFT automatically when we have it inside routing bank details
  useEffect(() => {
    if (!bicSwiftValue && routingNumberBankData && !isSwiftInputFocused) {
      setValue('bicSwift', routingNumberBankData.bicSwift, {
        shouldValidate: true,
      });
    }
  }, [routingNumberBankData, bicSwiftValue, isSwiftInputFocused, setValue]);

  // set SWIFT automatically when we have it inside iban bank details
  useEffect(() => {
    if (!bicSwiftValue && ibanBankData && !isSwiftInputFocused) {
      setValue('bicSwift', ibanBankData.bicSwift, {
        shouldValidate: true,
      });
    }
  }, [ibanBankData, bicSwiftValue, isSwiftInputFocused, setValue]);

  // show different fields based on conditions
  useEffect(() => {
    if (fieldsMetadata && recipientEntityType) {
      const metadataForUse =
        fieldsMetadata[recipientEntityType]?.[transferType];

      if (metadataForUse) {
        setFieldGroups(_orderBy(Object.values(metadataForUse), 'order'));
      }
    }
  }, [recipientEntityType, transferType, fieldsMetadata]);

  useEffect(() => {
    setIsLoadingMetadata(true);

    getRecipientCreationMetadata({
      accountCountry: accountCountry,
      currency: currencyValue.value.code,
    })
      .then((data: any) => {
        if (!data) {
          return;
        }

        setFieldsMetadata(data);
      })
      .catch()
      .finally(() => {
        setIsLoadingMetadata(false);
      });
  }, [getRecipientCreationMetadata, currencyValue, accountCountry]);

  const canBeRegular = useMemo(
    () =>
      !!(
        _get(fieldsMetadata, 'company.regular', null) ||
        _get(fieldsMetadata, 'individual.regular', null)
      ),
    [fieldsMetadata]
  );

  const canBeSwift = useMemo(
    () =>
      !!(
        _get(fieldsMetadata, 'company.priority', null) ||
        _get(fieldsMetadata, 'individual.priority', null)
      ),
    [fieldsMetadata]
  );

  const purposesToUse = useMemo(
    () =>
      purposes?.[recipientEntityType ?? '']?.map((item: any) => ({
        id: item.description,
        value: item,
        name: item.description,
      })),
    [purposes, recipientEntityType]
  );

  useEffect(() => {
    getTransferPurposes({
      currency: currencyValue?.value?.code,
      accountCountry,
    })
      .then(({ data }: any) => {
        setPurposes(data);
      })
      .catch((error: any) => console.log(error))
      .finally(() => {
        setIsPurposesLoading(false);
      });
  }, [getTransferPurposes, setPurposes, accountCountry, currencyValue]);

  useEffect(() => {
    if (!canBeSwift && canBeRegular) {
      setValue('transferType', TRANSFER_TYPE.regular);
    } else if (!canBeRegular && canBeSwift) {
      setValue('transferType', TRANSFER_TYPE.priority);
    }
  }, [canBeRegular, canBeSwift, setValue]);

  useEffect(() => {
    if (!recipientForEdit && currencyValue.id !== 'GBP') {
      if (currencyValue.id === 'EUR') {
        setValue('transferType', TRANSFER_TYPE.regular);
      } else {
        setValue('transferType', TRANSFER_TYPE.priority);
      }
    }
  }, [currencyValue, recipientForEdit, setValue]);

  const [entityCompanyDetails, setEntityCompanyDetails] = useState<
    Pick<IEntityDetails, 'countriesSendingMoneyTo'>
  >();

  useEffect(() => {
    const getEntityCompanyDetails = async () => {
      try {
        if (!entityId) {
          return;
        }

        if (!isEntityOnboarded) {
          setIsLoadingCompanyDetails(false);
          return;
        }

        setIsLoadingCompanyDetails(true);

        const response = await Firebase.getEntityCompanyDetails(entityId);

        if (response?.data?.success) {
          setEntityCompanyDetails(response.data.data);
        } else {
          Notify.error(response?.data?.message ?? '');
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingCompanyDetails(false);
      }
    };

    getEntityCompanyDetails();
  }, [entityId, isEntityOnboarded, setIsLoadingCompanyDetails]);

  const isCompanyType = recipientEntityType === RECIPIENT_TYPE.company;
  const orderedCountries = useMemo(() => _orderBy(countries, 'name', 'asc'), [
    countries,
  ]);
  const isRegular = useMemo(() => transferType === TRANSFER_TYPE.regular, [
    transferType,
  ]);
  const isRegularEUR = useMemo(
    () =>
      currencyValue.value.code === 'EUR' &&
      transferType === TRANSFER_TYPE.regular,
    [currencyValue, transferType]
  );

  const isSaveAsDraftButtonDisabled =
    isLoadingMetadata ||
    isLoadingCompanyDetails ||
    isSubmitting ||
    isCreatingDraftRecipient ||
    isCreatingRecipient;

  const isSaveFullContactButtonDisabled =
    isSaveAsDraftButtonDisabled || !isEntityOnboarded;

  const onSubmit = async ({
    values,
    isDraft = false,
    isScamAlertAnswered = false,
  }: {
    values: AddContactInputs;
    isDraft?: boolean;
    isScamAlertAnswered?: boolean;
  }) => {
    const {
      recipientName,
      routingNumber,
      routingNumber2,
      accountNumber,
      bicSwift,
      bankName,
      recipientCountry,
      currency,
      purpose,
      isBuyer,
      isSupplier,
      shouldSendRemittance,
      isTrusted,
      emails,
      ...restValues
    } = values;

    let accountDetails: Record<string, unknown>;

    if (!isCompanyType && recipientName.trim().indexOf(' ') === -1) {
      Notify.info('Please enter your both first name and last name.');
      return;
    }

    const isContactCountrySuspicious = isContactCountrySuspiciousCheck({
      entityKnownCountries: entityCompanyDetails?.countriesSendingMoneyTo,
      recipientCountry,
      recipientForEdit,
    });

    if (recipientForEdit && isContact(recipientForEdit) && isEntityOnboarded) {
      const contactPaymentDetailsChanged =
        dirtyFields.bicSwift ||
        dirtyFields.accountNumber ||
        dirtyFields.routingNumber ||
        dirtyFields.routingNumber2;

      if (
        !isScamAlertAnswered &&
        (contactPaymentDetailsChanged || isContactCountrySuspicious)
      ) {
        setScamAlertAnswer(undefined);
        setShowScamAlert(true);
        return;
      }
    } else {
      if (
        !isScamAlertAnswered &&
        isContactCountrySuspicious &&
        !scamAlertAnswer
      ) {
        setShowScamAlert(true);
        return;
      }
    }

    if (isRegularEUR) {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.iban],
        accountNumber,
        accountCountry,
      };
    } else if (isRegular) {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.local],
        routingType,
        routingNumber,
        routingType2,
        routingNumber2,
        accountNumber,
        bicSwift,
        accountCountry,
      };
    } else {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.swift],
        routingType,
        routingNumber,
        accountNumber,
        bicSwift,
        bankName,
        accountCountry,
      };
    }

    // USD and GBP usecases
    if (currency.value.code === 'USD' || currency.value.code === 'GBP') {
      accountDetails = {
        routingType,
        paymentType:
          transferType === TRANSFER_TYPE.priority
            ? PAYMENT_TYPE[PAYMENT_TYPE.swift]
            : PAYMENT_TYPE.local,
        routingNumber,
        accountNumber,
        bicSwift,
        bankName,
        accountCountry,
      };
    }

    if (!accountDetails) {
      Notify.error('Account details not correct.');
      return;
    }

    if (isDraft) {
      setIsCreatingDraftRecipient(true);
    } else {
      setIsCreatingRecipient(true);
    }

    const recipientData: IRecipient = {
      currency: currency.value.code,
      recipientEntityType,
      recipientCountry: recipientCountry?.alpha2,
      recipientName,
      isCustomer: !!isBuyer,
      isSupplier: !!isSupplier,
      shouldSendRemittance,
      purpose: purpose?.value,
      isTrusted,
      contactEmail: emails?.map((email) => email.value).join(','),
      // account details are read only, rework later when we have correct inputs and can omit react-hook-form
      ...(_omit(restValues, 'accountDetails', 'transferType') as any),
      ...accountDetails,
    };

    if (scamAlertAnswer) {
      recipientData.scamAlert = {
        answer: scamAlertAnswer,
        date: dayjs().format(DB_DATE_FORMAT),
      };
    }

    recipientData.externalRefs = {
      sourceSystem: recipientForEdit?.externalRefs?.sourceSystem,
      sourceSystemId: recipientForEdit?.externalRefs?.sourceSystemId,
    };

    if (isContact(recipientForEdit) && !isForceCreate) {
      // update recipient
      const response = await updateRecipient({
        recipientData,
        recipientId: recipientForEdit.id,
        isDraft,
      });

      if (isDraft) {
        setIsCreatingDraftRecipient(false);
      } else {
        setIsCreatingRecipient(false);
      }

      if (response && response.success) {
        const { id, data: newRecipient } = response;

        setRecipient?.({ ...newRecipient, id });
        onClose();
      }
    } else {
      const response = await createRecipient({
        recipient: recipientData,
        isDraft,
      });

      if (isDraft) {
        setIsCreatingDraftRecipient(false);
      } else {
        setIsCreatingRecipient(false);
      }

      if (response && response.success) {
        const { id, data: newRecipient } = response;
        if (!newRecipient.enabled) {
          onClose();
        } else {
          setRecipient?.({ ...newRecipient, id });

          // TODO: remove this hack later when we will take transfer type from recipient
          // setTransferTypeFromProps(transferType);
          if (onContinue) {
            onContinue();
          } else {
            onClose();
          }
        }
      }
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();

    const values = watch();
    let isValid = true;

    const valuesToValidate = Object.entries(values).reduce<string[]>(
      (total, [key, value]) => {
        if ((key === 'accountNumber' || key === 'bicSwift') && value) {
          total.push(key);
        }

        return total;
      },
      []
    );

    if (valuesToValidate.length) {
      isValid = await trigger(valuesToValidate);
    }

    if (isValid) {
      onSubmit({ values, isDraft: true });
    }
  };

  const addContactFormFieldData = {
    recipientForEdit,
    watch,
    control,
    setValue,
    errors,
    validateOnMount,
    swiftBankData,
    setSwiftBankData,
    setIsSwiftInputFocused,
    ibanBankData,
    setIbanBankData,
    routingNumberBankData,
    setRoutingNumberBankData,
    orderedCountries,
    countriesSearchValue,
    recipientCountry,
    currenciesSearchValue,
    setCurrenciesSearchValue,
    disableCurrency,
    setCountriesSearchValue,
    setAccountCountry,
    swiftOurs,
    swiftShared,
    sellCurrency,
    isPurposesLoading,
    purposeValue,
    purposesToUse,
    emails,
    setRoutingType,
    setRoutingType2,
  };

  return createPortal(
    <Popup
      HeaderContent={
        <Title variant="h3">
          {recipientForEdit && !isForceCreate
            ? 'Edit contact'
            : 'Add new contact'}
        </Title>
      }
      width="100%"
      maxWidth="1280px"
      height="840px"
      onClose={() => {
        if (
          !!Object.keys(dirtyFields).length &&
          !isAwaitingRegistration &&
          isEntityOnboarded
        ) {
          setOpenDirtyPopup(true);
        } else {
          onClose();
        }
      }}
      FooterContent={
        <PermissionsChecker
          action={recipientForEdit ? 'update' : 'create'}
          resource="recipients"
        >
          <StaleTooltip
            disabled={isEntityOnboarded}
            placement="top"
            content={
              <Paragraph color="white">
                Only fully onboarded companies can create full contacts.
              </Paragraph>
            }
          >
            <Button
              isLoading={isCreatingRecipient || isSubmitting}
              disabled={isSaveFullContactButtonDisabled}
              form="add-contact-form"
            >
              Save contact
            </Button>
          </StaleTooltip>
          {withSaveAsDraft &&
            (!recipientForEdit || recipientForEdit?.status === 'draft') && (
              <Button
                ml
                variant="secondary"
                isLoading={isCreatingDraftRecipient}
                disabled={isSaveAsDraftButtonDisabled}
                onClick={onSaveAsDraft}
              >
                Save as draft
              </Button>
            )}
        </PermissionsChecker>
      }
    >
      <AddContactFormWrapper>
        {isLoadingMetadata && (
          <LoaderWrapper>
            <Loader size="large" />
          </LoaderWrapper>
        )}

        <StyledForm
          id="add-contact-form"
          flex={1}
          onSubmit={(event) => {
            event.preventDefault();

            if (isAwaitingRegistration) {
              setOpenRegisterCompanyPopup(true);
              return;
            }

            if (!isCreatingRecipient) {
              handleSubmit((values) => onSubmit({ values }))();
            }
          }}
        >
          <Row
            flex={1}
            alignSelf="stretch"
            alignItems="flex-start"
            gap={theme.spacing.xl}
          >
            <Col flex={1} alignSelf="stretch" gap={theme.spacing.xl}>
              <FieldGroupWrapper>
                <Row gap={theme.spacing.m}>
                  <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                    Import details
                  </Subtitle>

                  <Row justifyContent="flex-end" gap={theme.spacing.m}>
                    <Button
                      disabled
                      variant="secondary"
                      onClick={() => {}}
                      icon="xero-ico"
                    >
                      From Xero
                    </Button>

                    <Button
                      disabled
                      variant="link"
                      icon="note-ico"
                      onClick={() => {}}
                    >
                      From Invoice
                    </Button>
                  </Row>
                </Row>
              </FieldGroupWrapper>

              <FieldGroupWrapper>
                <Col>
                  <Row justifyContent="flex-start">
                    <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                      Account details
                    </Subtitle>
                    <TypesRow>
                      <Controller
                        name="recipientEntityType"
                        control={control}
                        defaultValue={null}
                        render={({ onChange, value }) => (
                          <>
                            <StaleInputRadioNew
                              id="business"
                              label="Business"
                              checked={value === RECIPIENT_TYPE.company}
                              onChange={() => onChange(RECIPIENT_TYPE.company)}
                              disabled={
                                // Disable Recipient Type radio buttons when contact is not new or in DRAFT state.
                                (!!recipientForEdit &&
                                  recipientForEdit.status !==
                                    RECIPIENT_STATUS.draft) ||
                                !fieldsMetadata?.company
                              }
                            />
                            <StaleInputRadioNew
                              id="individual"
                              label="Individual"
                              checked={value === RECIPIENT_TYPE.individual}
                              onChange={() =>
                                onChange(RECIPIENT_TYPE.individual)
                              }
                              disabled={
                                // Disable Recipient Type radio buttons when contact is not new or in DRAFT state.
                                (!!recipientForEdit &&
                                  recipientForEdit.status !==
                                    RECIPIENT_STATUS.draft) ||
                                !fieldsMetadata?.individual
                              }
                            />
                          </>
                        )}
                      />
                    </TypesRow>
                  </Row>
                  <Row>
                    <Row mb flex={1} flexWrap="nowrap" alignItems="flex-start">
                      {accountGroupFields &&
                        _orderBy(accountGroupFields.fields, 'order').map(
                          (field) => (
                            <FieldWrapper
                              isCurrency={field.type === 'CURRENCY'}
                              accountGroup={true}
                              key={field.name}
                            >
                              <AddContactFormField
                                field={field}
                                {...addContactFormFieldData}
                              />
                            </FieldWrapper>
                          )
                        )}
                    </Row>
                  </Row>
                </Col>

                <Col>
                  <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                    Payment details
                  </Subtitle>

                  <Row justifyContent="flex-start">
                    <Paragraph mb>Payment method</Paragraph>
                    <TypesRow>
                      <Controller
                        name="transferType"
                        control={control}
                        defaultValue={null}
                        render={({ onChange, value }) => (
                          <>
                            <StaleInputRadioNew
                              id="transferType_local"
                              label="Local"
                              checked={value === TRANSFER_TYPE.regular}
                              onChange={() => onChange(TRANSFER_TYPE.regular)}
                              disabled={!canBeRegular}
                            />
                            <StaleInputRadioNew
                              id="transferType_swift"
                              label="SWIFT"
                              checked={value === TRANSFER_TYPE.priority}
                              onChange={() => onChange(TRANSFER_TYPE.priority)}
                              disabled={!canBeSwift}
                            />
                          </>
                        )}
                      />
                    </TypesRow>
                  </Row>
                  <Row
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap={theme.spacing.m}
                  >
                    {paymentGroupFields &&
                      _orderBy(paymentGroupFields.fields, 'order').map(
                        (field) => {
                          return (
                            <FieldRow
                              key={field.name}
                              fullWidth={
                                currencyValue?.id === 'EUR' &&
                                field.type === 'IBAN'
                              }
                            >
                              <FieldWrapper>
                                <AddContactFormField
                                  field={field}
                                  {...addContactFormFieldData}
                                />
                              </FieldWrapper>
                            </FieldRow>
                          );
                        }
                      )}
                  </Row>
                </Col>

                <Col mt>
                  <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                    Additional information
                  </Subtitle>

                  <Row
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap={theme.spacing.m}
                  >
                    {otherGroupFields &&
                      _orderBy(otherGroupFields.fields, 'order').map(
                        (field) => {
                          return (
                            <FieldRow key={field.name}>
                              <FieldWrapper>
                                <AddContactFormField
                                  field={field}
                                  {...addContactFormFieldData}
                                />
                              </FieldWrapper>
                            </FieldRow>
                          );
                        }
                      )}
                  </Row>
                </Col>

                {recipientForEdit?.isTrusted && (
                  <div style={{ marginTop: 14 }}>
                    <StaleCheckboxControlled
                      id="isTrusted"
                      control={control}
                      name="isTrusted"
                      Label={
                        <Paragraph variant="bold">Trusted Payee</Paragraph>
                      }
                    />
                  </div>
                )}
              </FieldGroupWrapper>
            </Col>

            <Col flex={1} alignSelf="stretch" gap={theme.spacing.xl}>
              <FieldGroupWrapper>
                <Col>
                  <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                    Detailed address
                  </Subtitle>
                  <Row
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap={theme.spacing.m}
                  >
                    {swiftGroupFields?.[0] &&
                      _orderBy(swiftGroupFields[0]?.fields, 'order').map(
                        (field) => (
                          <FieldRow
                            key={field.name}
                            fullWidth={field.name === 'recipientAddress'}
                          >
                            <FieldWrapper>
                              <AddContactFormField
                                showOptionalText
                                field={field}
                                {...addContactFormFieldData}
                              />
                            </FieldWrapper>
                          </FieldRow>
                        )
                      )}
                  </Row>
                </Col>
              </FieldGroupWrapper>

              <FieldGroupWrapper>
                {accountGroupFields && (
                  <>
                    <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                      Contact details for remittances
                    </Subtitle>

                    <Row
                      flexWrap="wrap"
                      alignItems="flex-start"
                      gap={theme.spacing.m}
                    >
                      {contactGroupFields &&
                        _orderBy(contactGroupFields?.fields, 'order').map(
                          (field) => (
                            <FieldRow
                              key={field.name}
                              fullWidth={field.name === 'defaultReference'}
                            >
                              <FieldWrapper>
                                <AddContactFormField
                                  field={field}
                                  {...addContactFormFieldData}
                                />
                              </FieldWrapper>
                            </FieldRow>
                          )
                        )}
                    </Row>
                  </>
                )}
              </FieldGroupWrapper>
            </Col>
          </Row>

          {recipientForEdit &&
            recipientForEdit.status === RECIPIENT_STATUS.draft &&
            withDraftWarning && (
              <Col mt>
                <Paragraph>
                  Please note that this contact cannot be used during payments
                  until all validation errors have been fixed.{' '}
                </Paragraph>
                <Paragraph color="red">Status: Draft</Paragraph>
              </Col>
            )}
        </StyledForm>

        {openRegisterCompanyPopup && (
          <StaleLimitedAccess
            onClose={() => setOpenRegisterCompanyPopup(false)}
          />
        )}

        {showScamAlert && (
          <ScamAlert
            isLoading={isCreatingRecipient || isSubmitting}
            isConfirmDisabled={isSaveFullContactButtonDisabled}
            onConfirm={() =>
              handleSubmit((values) =>
                onSubmit({ values, isScamAlertAnswered: true })
              )()
            }
            onClose={() => setShowScamAlert(false)}
            setScamAlertAnswer={setScamAlertAnswer}
            scamAlertAnswer={scamAlertAnswer}
          />
        )}

        {openDirtyPopup && (
          <DirtyPopup
            onSubmit={() => {
              setOpenDirtyPopup(false);
              if (!isCreatingRecipient) {
                handleSubmit((values) => onSubmit({ values }))();
              }
            }}
            onClose={onClose}
            disabled={isCreatingRecipient && !isEntityOnboarded}
          />
        )}
      </AddContactFormWrapper>
    </Popup>,
    document.body
  );
};

export default AddContactForm;
